/* PASSENGER INFO CARD */
.displayFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.displayFlex-1 {
  display: flex;
  align-items: center;
}

.cardStyle {
  border-radius: 8px !important;
  padding: 0.75rem !important;
  background-color: #fff !important;
  width: 140px;
}

.passengerIndex {
  color: #fff !important;
  font-size: 0.75rem !important;
  font-weight: bold !important;
  width: 18px !important;
  text-align: center !important;
  border-radius: 100% !important;
  background: #007dfe !important;
}

.passengerCardPrimaryInfo {
  color: #1a1a1a !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  white-space: nowrap;
}

.passengerCardSecondaryInfo {
  color: #ada6ae !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}

/* SEAT LAYOUT */
/* changes color of seat with status => available noAvailable and occupied */
.seatIndicators {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 6px;
}

/* indicates on page we are from at bottom of seat layout */
.indicator {
  font-size: 12px !important;
}

.seatBox {
  border-radius: 4px;
  width: 32px;
  height: 32px;
  text-align: center;
  margin-right: 8px !important;
  margin-left: 8px !important;
  padding: 5px !important;
}

/* SUBMIT SEAT CHANGE BUTTON */
.seatOrangeLight {
  height: 40px;
  padding: 15px !important;
  background: #fff !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 72px !important;
  text-transform: capitalize !important;
  color: #dd6b00 !important;
  border: 1px solid #dd6b00 !important;
  box-shadow: 0px 4px 8px hsla(29, 100%, 43%, 0.178);
}

.seatOrangeButton {
  padding: 16px !important;
  background: #dd6b00 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 72px !important;
  text-transform: capitalize !important;
  color: #fff !important;
}

.submitSeatContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  max-width: 640px;
  margin: auto;
}

.selectSeatContainer {
  max-height: calc(100vh - 340px);
  padding: 16px 16px 8px;
  overflow: scroll;
  overflow-x: hidden;
}

/* set z-index to 0 for all carousel child elements */
.carousel * {
  z-index: 0 !important;
}

.paxDetailCardList::-webkit-scrollbar {
  display: none;
}

/* condition for screen size less than 768px (mobiles)*/
@media (min-width: 768px) {
  .selectSeatContainer {
    max-height: calc(100vh - 333px);
  }
}
