.Container {
  background: linear-gradient(359deg, #fbfcfd, #f9fbfc);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.termsandconditions {
  color: #ada6ae !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  text-align: center !important;
}

.formContainer {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(24px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 16px;
}

.formTitle {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #2c2d6d !important;
  opacity: 0.6 !important;
}
.formData {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #2c2d6d !important;
}

.toggleButton {
  background: #2c2d6d !important;
  color: #ffffff !important;
}

.iconColor {
  color: #2c2d6d !important;
}

.stationListContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #80808078;
}
.stationListBlock {
  position: absolute;
  top: 5%;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  max-width: 640px;
  border-radius: 16px 16px 0 0;
  background: #fff;
}
.grayStick {
  margin: auto;
  width: 48px;
  height: 4px;
  background: #ddd;
  border-radius: 8px;
}

/* Station Dropdown */
.stationStatusHomepage {
  background: #fff !important;
  color: #dd6b00 !important;
  padding: 5px 13px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  outline: 1px solid #dd6b00 !important;
}
