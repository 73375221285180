body {
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 640px;
  margin: 0 auto;
  background-color: #f2f2f2;
  height: 100vh !important;
  overscroll-behavior-y: none;
}

.App {
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ~~~~~~~~~~~~~HEADER COMPONENT~~~~~~~~~~~~~ */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ddd;
}
.headerText {
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #1a1a1a !important;
}

/* ~~~~~~~~~~~~~FOOTER COMPONENT~~~~~~~~~~~~~ */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px;
  border-bottom: 2px solid #ed6b23;
}
.footerText {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #1a1a1a !important;
  margin-right: 6px;
}

/* ~~~~~~~~~~~~~ALL THE INPUT FIELDS (Dropdown/InputText box) LABELS COMPONENT~~~~~~~~~~~~~ */
.inputLabels {
  font-weight: bold !important;
  font-size: 12px !important;
  color: #515151 !important;
  margin-bottom: 8px !important;
  margin-top: 24px !important;
}

/* ~~~~~~~~~~~~~ALL THE SUBMIT BUTTONS ON PAGE END IN BLACK OR ORANGE~~~~~~~~~~~~~ */
.submitBlackButton {
  margin-top: 24px !important;
  height: 48px !important;
  background: #1a1a1a !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  color: #fff !important;
}
.submitOrangeButton {
  margin-top: 24px !important;
  background: #dd6b00 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 72px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  padding: 18px !important;
}
.submitOrangeButtonLight {
  margin-top: 24px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 72px !important;
  text-transform: capitalize !important;
  color: #dd6b00 !important;
  border: 1px solid #dd6b00 !important;
  padding: 16px !important;
}

.submitBlackButtonPopUp {
  margin-top: 24px !important;
  background: #dd6b00 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 72px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  padding: 10px !important;
}

/* ~~~~~~~~~~~~~ALL THE CARDS ON PAGE~~~~~~~~~~~~~ */
.paperCards {
  border-radius: 8px !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
  padding: 16px;
}
.dropShadow {
  border-radius: 8px !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
}

/* ~~~~~~~~~~~~~ALL THE SELECT INPUTS~~~~~~~~~~~~~ */
.selectInputs {
  height: 40px !important;
  border-radius: 8px !important;
}
.iconArrowInSelectInput {
  margin-right: 12px !important;
  font-size: 15px !important;
  color: #2d4a5b !important;
}

.selectInputDataFont {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #1a1a1a !important;
}

.displayInlineFlex {
  display: inline-flex;
  align-items: center;
}

.addContactInfoBox {
  margin-top: 24px;
  padding: 8px;
  border-radius: 4px;
  background-color: #fff6df;
}
