@keyframes ldio-dhj58xzoplv {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.5, 1.5);
  }
  100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
  }
}
.ldio-dhj58xzoplv div > div {
  position: absolute;
  width: 5.2px;
  height: 5.2px;
  border-radius: 50%;
  background: #0a0a0a;
  animation: ldio-dhj58xzoplv 1s linear infinite;
}
.ldio-dhj58xzoplv div:nth-child(1) > div {
  left: 122px;
  top: 86px;
  animation-delay: -0.9s;
}
.ldio-dhj58xzoplv > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 125.60000000000001px 89.60000000000001px;
}
.ldio-dhj58xzoplv div:nth-child(2) > div {
  left: 116px;
  top: 108px;
  animation-delay: -0.8s;
}
.ldio-dhj58xzoplv > div:nth-child(2) {
  transform: rotate(36deg);
  transform-origin: 119.60000000000001px 111.60000000000001px;
}
.ldio-dhj58xzoplv div:nth-child(3) > div {
  left: 98px;
  top: 121px;
  animation-delay: -0.7s;
}
.ldio-dhj58xzoplv > div:nth-child(3) {
  transform: rotate(72deg);
  transform-origin: 101.60000000000001px 124.60000000000001px;
}
.ldio-dhj58xzoplv div:nth-child(4) > div {
  left: 75px;
  top: 121px;
  animation-delay: -0.6s;
}
.ldio-dhj58xzoplv > div:nth-child(4) {
  transform: rotate(108deg);
  transform-origin: 78.60000000000001px 124.60000000000001px;
}
.ldio-dhj58xzoplv div:nth-child(5) > div {
  left: 57px;
  top: 108px;
  animation-delay: -0.5s;
}
.ldio-dhj58xzoplv > div:nth-child(5) {
  transform: rotate(144deg);
  transform-origin: 60.60000000000001px 111.60000000000001px;
}
.ldio-dhj58xzoplv div:nth-child(6) > div {
  left: 50px;
  top: 86px;
  animation-delay: -0.4s;
}
.ldio-dhj58xzoplv > div:nth-child(6) {
  transform: rotate(180deg);
  transform-origin: 53.60000000000001px 89.60000000000001px;
}
.ldio-dhj58xzoplv div:nth-child(7) > div {
  left: 57px;
  top: 65px;
  animation-delay: -0.3s;
}
.ldio-dhj58xzoplv > div:nth-child(7) {
  transform: rotate(216deg);
  transform-origin: 60.60000000000001px 68.60000000000001px;
}
.ldio-dhj58xzoplv div:nth-child(8) > div {
  left: 75px;
  top: 52px;
  animation-delay: -0.2s;
}
.ldio-dhj58xzoplv > div:nth-child(8) {
  transform: rotate(252deg);
  transform-origin: 78.60000000000001px 55.60000000000001px;
}
.ldio-dhj58xzoplv div:nth-child(9) > div {
  left: 98px;
  top: 52px;
  animation-delay: -0.1s;
}
.ldio-dhj58xzoplv > div:nth-child(9) {
  transform: rotate(288deg);
  transform-origin: 101.60000000000001px 55.60000000000001px;
}
.ldio-dhj58xzoplv div:nth-child(10) > div {
  left: 116px;
  top: 65px;
  animation-delay: 0s;
}
.ldio-dhj58xzoplv > div:nth-child(10) {
  transform: rotate(324deg);
  transform-origin: 119.60000000000001px 68.60000000000001px;
}
.loadingio-spinner-spin-yq8n5svaih {
  width: 180px;
  height: 180px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-dhj58xzoplv {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-dhj58xzoplv div {
  box-sizing: content-box;
}
