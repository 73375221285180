.stationNames {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #1a1a1a !important;
  text-transform: capitalize !important;
}
.selectedDuration {
  color: #615a5a !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}

.StationSelection {
  background: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.StationSelectionForm {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  padding: 0 16px 16px;
  border-bottom: 1px solid #ddd;
}

.selectStationDropdown {
  height: 40px !important;
  border-radius: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 0 12px !important;
  border: 1px solid #ddd !important;
}
.selectStationDropdown-error {
  height: 40px !important;
  border-radius: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 0 12px !important;
  border: 1px solid #d32f2f !important;
}

/* ~~~~~~~~~~~~~Select Station Component~~~~~~~~~~~~~ */
.sliderStationList {
  position: absolute !important;
  top: 30px !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  border-radius: 0 0 !important;
  box-shadow: 0 0 0 !important;
  max-width: 640px !important;
  margin: auto !important;
}
.selectStationSliderHeader {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin: 16px 0 !important;
}
.stationlistContainer {
  margin: 16px 0 !important;
  height: 100% !important;
}
.stationlistDropdown {
  position: absolute;
  margin: 16px 0 !important;
  overflow-y: scroll;
  height: 70% !important;
  max-width: 640px;
  right: 0px;
  left: 0px;
  padding: 0px 20px;
}
.stationNameBlock {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #ddd !important;
  padding: 12px 0 !important;
}
.stationName {
  font-weight: bold !important;
  font-size: 14px !important;
  color: #1a1a1a !important;
}

/* ~~~~~~~~~~~~~Select Station Card Price Component~~~~~~~~~~~~~ */
.stationStatus {
  background: #f2f2f2 !important;
  color: #999 !important;
  padding: 5px 13px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  border-radius: 4px !important;
}
.stationCardName {
  font-weight: 700 !important;
  font-size: 14px !important;
}
.stationCardClass {
  font-size: 12px !important;
}
.stationCardPrice {
  font-weight: 700 !important;
  font-size: 14px !important;
}
.stationTimeLabel {
  font-size: 10px !important;
  background: #eae8ec !important;
  text-align: center !important;
  border-radius: 100px !important;
  padding: 2px 5px !important;
  margin-bottom: 4px !important;
}
.stationTime {
  font-size: 12px !important;
  text-align: center !important;
}
.totalDuration {
  background: #eae8ec !important;
  padding: 1px 10px !important;
  border-radius: 100px !important;
  font-size: 10px !important;
}

.grayOut {
  color: #777 !important;
}

/* ~~~~~~~~~~~~~Redirect Component~~~~~~~~~~~~~ */
.redirectPageText {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 13px 0px 13px;
}

.terms {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px;
  color: #ada6ae;
  align-items: center;
  text-align: center;
}
