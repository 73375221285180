.passengerContainer {
    position: fixed; 
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #80808078;    
}

.passengerBlock {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin:  auto;
    max-width: 640px;
    border-radius: 8px 8px 0 0;
    background: #FFF;
}

.grayStick {
    width: 48px;
    height: 4px;
    margin: auto;
    background: #DDD;
    border-radius: 16px;
    margin-top: 8px;
}

.popupTitle {
    color: #1A1A1A !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

/* CART STYLES */
.passengerCartContainer {
    border-bottom: 1px solid #DDD;
    padding: 12px 0;
}
.passengerCartIcon {
    color: #2C2D6D;
}
.passengerCartTitle {
    color: #1A1A1A !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}
.passengerCartAgeInfo {
    color: #615A5A !important;
    font-weight: 400 !important;
    font-size: 12px !important;
}
.passengerAddedToCart {
    color: #1A1A1A !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    margin: auto 10px !important;
}